/**
 * Copyright 2022 Design Barn Inc.
 */

import { css } from "lit-element";

import styles from "./lottie-player.styles";

export default css`
  ${styles}

  :host {
    width: 512px;
    height: 512px;
  }
`;
